import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'dialog-delete-category',
  props: {
    removingItem: {
      type: Object | null
    },
    openDialogDelete: {
      type: Boolean
    }
  },
  watch: {
    openDialogDelete () {
      this.reset()
      this.showRemoveDialog = true
      this.fetchList({ tag: this.removingItem.id })
    }
  },
  data () {
    return {
      showRemoveDialog: false
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'newsTags/isLoading',
      newsList: 'news/newsList',
      loading: 'news/newsListLoading'
    }),
    headers () {
      return [
        {
          text: 'Id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Date created',
          value: 'createdAt'
        },
        {
          text: 'Status',
          value: 'status'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'news/NEWS_FETCH_LIST',
      remove: 'newsTags/DELETE'
    }),
    ...mapMutations({
      reset: 'news/RESET'
    }),
    removeItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    }
  }
}
