import { mapActions, mapGetters, mapMutations } from 'vuex'
import dialogView from './dialog-view/index.vue'
import dialogDelete from './dialog-delete/index.vue'
import CountryFlag from 'vue-country-flag'
import { debounce } from 'lodash'

export default {
  name: 'setting-education-categories',
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null,
      filter: {
        keywords: '',
        status: '',
        countries: ''
      }
    }
  },
  components: {
    dialogView,
    dialogDelete,
    CountryFlag
  },
  computed: {
    ...mapGetters({
      countries: 'setting/countries',
      accessCountries: 'profile/accessCountries',
      list: 'newsTags/list',
      limit: 'newsTags/limit',
      listLength: 'newsTags/listLength',
      isLoading: 'newsTags/isLoading',
      isListLoading: 'newsTags/isListLoading',
      openDialogView: 'newsTags/openDialogView',
      setting: 'news/setting'
    }),
    headers () {
      return [
        {
          text: 'Id',
          align: 'start',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    },
  },
  created () {
    this.fetchList(this.filter)
    this.setting || this.fetchSetting()
  },
  methods: {
    ...mapActions({
      fetchList: 'newsTags/GET_LIST',
      fetchSetting: 'news/GET_SETTING'
    }),
    ...mapMutations({
      changeSkip: 'newsTags/CHANGE_SKIP',
      changeDialogView: 'newsTags/CHANGE_DIALOG_VIEW',
      setList: 'newsTags/SET_LIST',
      changeEditId: 'newsTags/CHANGE_EDIT_ID'
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.sendRequest()
    },
    changeDialog (status = false, item = null) {
      this.changeEditId(item.id)
      this.changeDialogView(status)
    },
    changeDialogDeleteItem (item) {
      this.removingItem = item
      this.showRemoveDialog = !this.showRemoveDialog
    },
    sendRequest: debounce(function () {
      this.fetchList(this.filter)
    }, 1000)
  },
  destroyed () {
    this.setList([])
  }
}
