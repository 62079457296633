import mixins from '@/mixins'
import validation from '@/mixins/validation'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import course from '@/mixins/course'
import SlugNameInput from '@/components/slug-name-input/index.vue'
import { snakeCase } from 'lodash'

export default {
  name: 'news-tags-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        name: '',
        countryId: '',
        status: '',
        defaultLocale: ''
      }
    }
  },
  watch: {
    currentItem () {
      if (this.currentItem && this.editId) {
        this.setData()
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'newsTags/isLoading',
      currentItem: 'newsTags/currentItem',
      list: 'newsTags/list',
      editId: 'newsTags/editId',
      accessCountries: 'profile/accessCountries',
      setting: 'news/setting'
    }),
    listLocales () {
      if (this.accessCountries) {
        const currentCountry = this.accessCountries.find(e => e.country_id === this.payload.countryId)
        if (currentCountry) {
          return currentCountry.locales.data
        } else {
          return []
        }
      }
      return []
    },
  },
  components: {
    SlugNameInput
  },
  created () {
    if (this.currentItem) {
      this.setData()
    }

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapActions({
      update: 'newsTags/UPDATE',
      create: 'newsTags/CREATE'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),
    setData () {
      Object.assign(this.payload, this.currentItem)
      this.setCountryIds([this.payload.countryId])
    },
    createRequestPayload () {
      const formData = new FormData()

      for (let i in this.payload) {
        formData.append(snakeCase(i), this.payload[i] || '')
      }

      this.currentItem ? formData.append('_method', 'patch') : ''
      return formData
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'));
          })

        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'));
		  })
        }
      }
    }
  }
}
